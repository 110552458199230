export default {
  props: {
    dataset: {
      type: Object,
      default: () => ({}),
    },
  },
  data: vm => ({
    propsClass: vm.dataset.class || '',
    needProof: false,
    modalName: 'call-order-modal-d',
    isOpenModal: false,
    hashName: 'call-order',
    showPhoneCallback: false,
  }),
  mounted() {
    if (this.getHash().includes(this.hashName)) {
      this.openModal();
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path && (to.hash.includes(this.hashName) && from.hash === '')) {
        this.openModal();
      } else if (to.path === from.path && (from.hash.includes(this.hashName) && to.hash === '')) {
        this.hideModel();
      }
    },
  },
  methods: {
    openModal() {
      this.showPhoneCallback = true;
      this.$nextTick(() => {
        this.$modal.show(this.modalName);
      });
    },
    hideModel() {
      this.$modal.hide(this.modalName);
      this.$nextTick(() => {
        this.showPhoneCallback = false;
      });
    },
    handlerNeedProof(isNeed) {
      this.needProof = isNeed;
    },
    handlerOpenedModal() {
      if (!this.getHash().includes(this.hashName)) {
        this.$router.push({ hash: this.hashName, query: this.$route.query });
      }
      this.isOpenModal = true;
    },
    handlerClosedModal() {
      this.isOpenModal = false;
      this.needProof = false;
      if (this.getHash().includes(this.hashName)) {
        this.$router.push({ hash: '', query: this.$route.query });
      }
    },
    getHash() {
      return this.$route.hash;
    },
  },
};
