import { getCurrentInstance } from '@nuxtjs/composition-api';

const useModal = () => {
  const vm = getCurrentInstance();

  return {
    ...vm.root.proxy.$modal,
  };
};

export default useModal;
