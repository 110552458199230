<template>
  <app-link class="header-cart" href="/personal/cart/" aria-label="Перейти в корзину">
    <cart-empty-icon class="header-cart__icon" width="40" height="36" />
    <client-only>
      <span v-if="counterSmallBasket" class="header-cart__counter">{{ counterSmallBasket }}</span>
    </client-only>
  </app-link>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { useBasketContext } from '@/compositions/basket/basketStorage';

import CartEmptyIcon from '@/assets/sprite/svg/cart_empty.svg';

export default defineComponent({
  name: 'HeaderCart',
  components: { CartEmptyIcon },
  setup() {
    const { counterSmallBasket } = useBasketContext();

    return {
      counterSmallBasket,
    };
  },
});
</script>

<style lang="less">
.header-cart {
  display: block;
  position: relative;

  &__counter {
    .small2;

    position: absolute;
    left: 12px;
    top: -1px;
    color: @white;
    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: @size-x3;
    background-color: @green;
    border: solid 2px @white;
    font-weight: 600;
    text-align: center;
    padding: 3px;
  }

  &__icon {
    fill: #8895b7;
    transition: @A fill;
  }

  &:hover &__icon,
  &:focus &__icon {
    fill: @link;
  }
}
</style>
