<template>
  <modal
    class="_custom"
    :name="name"
    :scrollable="scrollable"
    :adaptive="adaptive"
    :width="width"
    :height="height"
    :click-to-close="isClosableToOverlay"
    v-bind="$attrs"
    @before-open="handlerBeforeOpen"
    @before-close="handlerBeforeClose"
    @opened="handlerOpened"
    @closed="handlerClosed"
  >
    <div class="modal-desktop" :class="rootClassModal">
      <div v-if="showHeader" class="modal-desktop__header">
        <button
          v-if="showBack"
          class="modal-desktop__back"
          type="button"
          aria-label="Назад"
          @click.prevent="onBack"
        >
          <svg-icon
            name="24/Arrow_back_24"
            class="modal-desktop__back-icon"
            :width="24"
            :height="24"
          />
        </button>
        <button
          v-if="showClose"
          class="modal-desktop__close"
          type="button"
          aria-label="Закрыть"
          @click.prevent="onHide"
        >
          <svg-icon
            name="24/Close_24"
            class="modal-desktop__close-icon"
            :width="24"
            :height="24"
          />
        </button>
      </div>
      <div class="modal-desktop__container">
        <slot></slot>
      </div>
    </div>
  </modal>
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  toRefs,
  computed,
} from '@nuxtjs/composition-api';

import useModal from '@/compositions/modal/useModal.js';
import useModalHandlers from '@/compositions/modal/useModalHandlers.js';

export default defineComponent({
  name: 'ModalVueD',
  props: {
    name: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    classModal: {
      type: [String, Array, Object],
      default: '',
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    isClosableToOverlay: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    adaptive: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const {
      handlerBeforeOpen,
      handlerBeforeClose,
      handlerOpened,
      handlerClosed,
      unlockScroll,
    } = useModalHandlers();
    const modal = useModal();

    const { name: modalName } = toRefs(props);

    const onHide = () => {
      modal.hide(modalName.value);
      emit('hide');
    };

    const onBack = () => {
      emit('bake');
    };

    const showHeader = computed({
      get: () => props.showClose || props.showBack,
      set: () => {},
    });

    const rootClassModal = computed({
      get: () => [
        props.classModal,
        {
          '_no-header': !showHeader.value,
        },
      ],
      set: () => {},
    });

    onUnmounted(() => {
      unlockScroll();
    });

    return {
      onHide,
      onBack,
      handlerBeforeOpen,
      handlerBeforeClose,
      handlerOpened,
      handlerClosed,
      showHeader,
      rootClassModal,
    };
  },
});
</script>

<style lang="less">
@import "./styles/index";
@import "./styles/_no-header";
@import "./styles/_city";
@import "./styles/_map-pvz-list";
</style>
