<template>
  <button-control
    size="small"
    :type="buttonType"
    is-block
    @click.prevent="openModal"
  >
    Заказать звонок
    <portal to="ModalsTarget">
      <modal-vue-d
        :name="modalName"
        width="630"
        @opened="handlerOpenedModal"
        @closed="handlerClosedModal"
      >
        <form-callback-d v-if="showPhoneCallback" @need-proof="handlerNeedProof" />
      </modal-vue-d>
    </portal>
  </button-control>
</template>

<script>
import { ButtonControl } from '@book24/ui-components';

import ModalVueD from '@/components/ModalVueD';

import CallOrderButtonMixin from '@/components/_desktop/AppHeader/Callback/buttons/CallOrderButtonMixin';

export default {
  name: 'CallOrderHeaderD',
  components: {
    FormCallbackD: () => import('@/components/_common/CallbackFrom/index.vue'),
    ModalVueD,
    ButtonControl,
  },
  mixins: [CallOrderButtonMixin],
  props: {
    buttonType: { type: String, default: 'secondary' },
  },
};
</script>
