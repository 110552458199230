import { computed, useStore } from '@nuxtjs/composition-api';

const useNuxtRenderKey = () => {
  const store = useStore();

  const nuxtRenderKey = computed(() => store.state.main.nuxtRenderKey);

  return { nuxtRenderKey };
};

export default useNuxtRenderKey;
